<template>
  <v-card class="elevation-1" :loading="formLoading" :disabled="formLoading">
    <v-card-text>
      <p class="mb-4 text-5">
        <v-icon>{{icon.info}}</v-icon> Select parameter then click generate.</p>
      <v-form>

        <!-- View As -->
        <v-radio-group
          v-model="viewAs"
          row
          class="pt-0 mt-0"
        >
          <v-radio
            label="Table View"
            value="table-view"
          ></v-radio>
          <v-radio
            label="PDF"
            value="pdf"
          ></v-radio>
          <v-radio
            label="Excel"
            value="excel"
          ></v-radio>
        </v-radio-group>

        <!-- status -->
        <v-radio-group
          v-model="status"
          row
          class="pt-0 mt-0"
        >
          <v-radio
            label="Active"
            value="active"
          ></v-radio>
          <v-radio
            label="Separated"
            value="separated"
          ></v-radio>
        </v-radio-group>

        <v-checkbox
          class="mt-0"
          v-model="dole"
          label="Dole Requirements"
        ></v-checkbox>

        <!-- group -->
        <v-autocomplete
          v-model="group"
          outlined
          dense
          clearable
          label="Division Group"
          item-value="id"
          item-text="title"
          :items="groups"
          :loading="groupLoading"
          :class="formErrorFields.groupErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.groupErrorMessage"
          @change="formError().remove('group', formErrorFields)"
        ></v-autocomplete>

        <!-- by -->
        <v-autocomplete
          v-model="by"
          :items="['Department', 'Outlet / Location']"
          outlined
          dense
          clearable
          label="Group By"
          :class="formErrorFields.groupErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.byErrorMessage"
          @keydown="formError().remove('by', formErrorFields)"
        ></v-autocomplete>

        <!-- bank -->
        <v-autocomplete
          v-model="bank"
          outlined
          dense
          clearable
          label="Bank"
          item-value="id"
          item-text="name"
          :items="banks"
          :loading="bankLoading"
          :class="formErrorFields.bankErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.bankErrorMessage"
          @change="formError().remove('bank', formErrorFields)"
        ></v-autocomplete>

        <!-- payroll period -->
        <v-dialog
          ref="dialog"
          v-model="modalPayrollPeriod"
          :return-value.sync="payrollPeriodValue"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :loading="payrollPeriodLoading"
              v-model="payrollPeriod"
              label="Payroll Period"
              outlined
              dense
              placeholder="Payroll Period"
              v-bind="attrs"
              v-on="on"
              autocomplete="off"
              :append-icon="icon.calendar"
              :class="formErrorFields.payrollPeriodErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.payrollPeriodErrorMessage"

              @click="formError().remove('payrollPeriod', formErrorFields)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="payrollPeriod" scrollable>
            <v-btn text color="primary" @click="modalPayrollPeriod=false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalPayrollPeriod=false">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-btn
          color="primary"
          :loading="loading"
          @click="generate">
          Generate
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          @click="reset"
        >
          Reset
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      :color="messageStatus"
      @close="message=''"
    >
    </snackbar>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiCalendarOutline, mdiInformationOutline } from '@mdi/js'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import get from '@/composables/get'
import blob from '@/composables/blob'
import fnx from '@/functions/fn'

export default {
  components: {
    snackbar,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const icon = { calendar: mdiCalendarOutline, info: mdiInformationOutline }
    const payrollPeriodValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const activePayrollPeriod = ref('')
    const payrollPeriodLoading = ref(false)
    const payrollPeriod = ref('')
    const modalPayrollPeriod = ref(null)
    const viewAs = ref('table-view')
    const status = ref('active')
    const group = ref('')
    const groups = ref([])
    const groupLoading = ref(false)
    const bank = ref('')
    const banks = ref([])
    const bankLoading = ref(false)
    const by = ref('')
    const dole = ref(false)
    const formLoading = ref(false)
    const formErrorFields = ref({
      payrollPeriodErrorMessage: '',
      statusErrorMessage: '',
      groupErrorMessage: '',
      byErrorMessage: '',
      bankErrorMessage: '',
    })
    const message = ref('')
    const messageStatus = ref('')
    const error = ref('')
    const reportData = ref(null)

    const generate = async () => {
      console.log(dole.value)
      const formData = {
        status: status.value,
        group: group.value,
        by: by.value,
        bank: bank.value,
        payrollPeriod: payrollPeriod.value,
        viewAs: viewAs.value,
        dole: dole.value,
      }

      if (viewAs.value === 'table-view') {
        emit('sendFormData', formData)
      } else {
        let mimetype = ''
        if (viewAs.value === 'pdf') {
          mimetype = 'application/pdf'
        } else {
          mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }

        await blob(`${process.env.VUE_APP_URI}/api/payroll/reports/pay-register/preview/${fnx.base64_encode(formData)}`, reportData, formLoading, error)
        if (error.value.data !== undefined) {
          message.value = error.value.data.message
          messageStatus.value = error.value.status
        } else {
          console.log(reportData.value)
          const url = window.URL.createObjectURL(new Blob([reportData.value], { type: mimetype }))
          window.open(url, '_blank')
        }
      }
    }

    const reset = () => {
      viewAs.value = 'table-view'
      status.value = 'active'
      group.value = ''
      by.value = ''
      bank.value = ''
      payrollPeriod.value = ''
      dole.value = false
    }

    get(`${process.env.VUE_APP_URI}/api/payroll/scheduler/groups`, groups, groupLoading)
    get(`${process.env.VUE_APP_URI}/api/payroll/banks`, banks, bankLoading)

    const getPayPeriod = async () => {
      await get(`${process.env.VUE_APP_URI}/api/payroll/payroll-periods/get-active`, activePayrollPeriod, payrollPeriodLoading, error)
      if (error.value.data !== undefined) {
        message.value = error.value.data.message
        messageStatus.value = error.value.status
      } else {
        payrollPeriod.value = activePayrollPeriod.value.end
      }
    }

    getPayPeriod()

    return {
      payrollPeriodLoading,
      payrollPeriod,
      modalPayrollPeriod,
      payrollPeriodValue,
      viewAs,
      status,
      group,
      groups,
      groupLoading,
      dole,
      by,
      bank,
      banks,
      bankLoading,
      icon,
      formLoading,
      reset,
      generate,
      loading,
      formErrorFields,
      formError,
      message,
      messageStatus,
    }
  },
}
</script>
